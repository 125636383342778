import {
  TSetSearchParamsProp,
  TSortedSearchParams,
  useSearchParams,
} from "src/router/useSearchParams"
import { Nullable } from "src/utils/tsUtil"

type TParadiseOrganizationsFiltersConfig = {
  organizations_offset: number
  organizations_id: string
  organizations_name: string
}

export type TParadiseOrganizationsFilters =
  Nullable<TParadiseOrganizationsFiltersConfig>
export type TSortedParadiseOrganizationsFilters =
  TSortedSearchParams<TParadiseOrganizationsFiltersConfig>

export function useParadiseOrganizationsFilter(): {
  filter: TParadiseOrganizationsFilters
  sortedFilter: TSortedParadiseOrganizationsFilters
  setFilter: TSetSearchParamsProp<TParadiseOrganizationsFilters>
  setOffset: (offset: number) => void
  offset: number
} {
  const {
    searchParams: filter,
    setSearchParams: setFilter,
    setSearchParamsAll,
    sortedSearchParams: sortedFilter,
  } = useSearchParams({
    keys: [
      {
        key: "organizations_offset",
        type: "number",
      },
      {
        key: "organizations_id",
        type: "string",
      },
      {
        key: "organizations_name",
        type: "string",
      },
    ],
  })

  return {
    filter,
    sortedFilter: sortedFilter.filter((f) => f.key !== "organizations_offset"),
    setFilter: (key, value) => {
      if (key === "organizations_offset") {
        return
      }

      // Make sure we reset paging on filter
      setSearchParamsAll({
        [key]: value,
        organizations_offset: 0,
      })
    },
    setOffset: (offset: number) => {
      setFilter("organizations_offset", offset)
    },
    offset: filter.organizations_offset || 0,
  }
}
