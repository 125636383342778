import { SearchFilter } from "src/components/Filter/SearchFilter"
import { ParadiseAppliedFilters } from "src/components/Paradise/ParadiseAppliedFilters"
import {
  TParadiseOrganizationsFilters,
  useParadiseOrganizationsFilter,
} from "src/components/Paradise/ParadiseOrganizations/useParadiseOrganizationsFilter"
import { ParadisePager } from "src/components/Paradise/ParadisePager"
import { ParadiseTable } from "src/components/Paradise/ParadiseTable"
import {
  FilterWrapper,
  IdWrapper,
  ParadiseLayout,
  ParadiseTitle,
  TopWrapper,
} from "src/components/Paradise/sharedStyles"
import { useFetchOrganizations } from "src/data/organizations/queries/organizationQueries"
import { IOrganization } from "src/data/organizations/types/organizationTypes"
import { Routes } from "src/router/routes"
import { useRouter } from "src/router/useRouter"
import { mColors } from "src/ui/colors"
import { DropdownMultiSelect } from "src/ui/DropdownSelect/DropdownMultiSelect"
import { ColumnPopoverWrapper } from "src/ui/GridTable/useTableColumns/tableColumnStyles"
import {
  TableColumn,
  useTableColumns,
} from "src/ui/GridTable/useTableColumns/useTableColumns"
import FilterIcon from "src/ui/icons/calibrating.svg"
import InfoIcon from "src/ui/icons/info.svg"
import { InfoBox } from "src/ui/InfoBox/InfoBox"
import { Titlebar } from "src/ui/Layout/Titlebar"
import { InternalLink } from "src/ui/Link/InternalLink"
import { MBadge } from "src/ui/MBadge/MBadge"
import { MText } from "src/ui/MText"
import { MTooltip } from "src/ui/MTooltip/MTooltip"
import { spacing } from "src/ui/spacing"
import { formatUtcDate } from "src/utils/l10n"

const organizationsFilterLabel: Record<
  keyof TParadiseOrganizationsFilters,
  string
> = {
  organizations_offset: "Offset",
  organizations_id: "Organization ID",
  organizations_name: "Name",
} as const

const LIMIT = 20

export function ParadiseOrganizations() {
  const { navigate } = useRouter()

  const { filter, sortedFilter, setFilter, offset, setOffset } =
    useParadiseOrganizationsFilter()

  const tableColumns: TableColumn<IOrganization>[] = [
    {
      value: "organization_id",
      label: "Organization id",
      disabled: true,
      columnWidth: "min-content",
      disableClickPropagation: true,
      renderCell: (organization) => {
        return (
          <IdWrapper>
            <InternalLink
              to={Routes.ParadiseOrganization.location(organization.id)}
            >
              {organization.id}
            </InternalLink>
            {organization.deleted && (
              <MTooltip title="Deleted">
                <InfoIcon width={12} fill={mColors.systemError} />
              </MTooltip>
            )}
          </IdWrapper>
        )
      },
      popoverContent: (
        <ColumnPopoverWrapper>
          <SearchFilter
            initialValue={filter.organizations_id ?? undefined}
            placeholder="Search by ID"
            onChange={(v) => setFilter("organizations_id", v)}
          />
          <MText variant="bodyS">Make sure to type in the exact ID</MText>
        </ColumnPopoverWrapper>
      ),
      popoverIcon: FilterIcon,
    },
    {
      value: "name",
      label: "Name",
      disabled: true,
      columnWidth: "min-content",
      renderCell: (organization) => {
        return <div>{organization.name}</div>
      },
      popoverContent: (
        <ColumnPopoverWrapper>
          <SearchFilter
            initialValue={filter.organizations_name ?? undefined}
            placeholder="Search by name"
            onChange={(v) => setFilter("organizations_name", v)}
          />
        </ColumnPopoverWrapper>
      ),
      popoverIcon: FilterIcon,
    },
    {
      value: "owner_id",
      label: "Owner id",
      columnWidth: "min-content",
      disableClickPropagation: true,
      renderCell: (organization) => {
        if (organization.owner_id) {
          return (
            <InternalLink
              to={Routes.ParadiseUser.location(organization.owner_id)}
            >
              {organization.owner_id}
            </InternalLink>
          )
        }

        return "-"
      },
    },
    {
      value: "created_at",
      label: "Created at",
      columnWidth: "auto",
      renderCell: (organization) => {
        if (organization.created_at) {
          return <div>{formatUtcDate({ date: organization.created_at })}</div>
        }

        return "-"
      },
    },
  ]

  const fetchOrganizations = useFetchOrganizations({
    params: {
      limit: LIMIT,
      offset: offset,
      name: filter.organizations_name || undefined,
      id: filter.organizations_id || undefined,
    },
    options: {
      keepPreviousData: true,
    },
  })

  const {
    headerElements,
    rows,
    templateColumns,
    interactiveColumns,
    interactiveVisibleColumns,
    updateColumnVisibility,
  } = useTableColumns({
    columns: tableColumns,
    data: fetchOrganizations.data?.organizations,
    options: {
      localStorageKey: "minut.paradise.organitions.columns",
    },
  })

  return (
    <ParadiseLayout>
      <Titlebar
        bottomMargin={spacing.S}
        title={
          <ParadiseTitle>
            Organizations
            {fetchOrganizations.isSuccess && (
              <MBadge color="info">
                Total: {fetchOrganizations.data?.paging.total_count}
              </MBadge>
            )}
          </ParadiseTitle>
        }
      />
      <TopWrapper>
        <FilterWrapper>
          <ParadiseAppliedFilters
            filters={sortedFilter.map((f) => ({
              label: organizationsFilterLabel[f.key],
              value: String(f.value),
              onRemove: () => setFilter(f.key, null),
            }))}
          />
        </FilterWrapper>
        <DropdownMultiSelect
          label="Columns"
          options={interactiveColumns}
          selectedValues={interactiveVisibleColumns.map((c) => c.value)}
          onChange={({ checked, option }) => {
            updateColumnVisibility(option.value, !checked)
          }}
        />
      </TopWrapper>
      <ParadiseTable
        header={headerElements}
        rows={rows ?? []}
        templateColumns={templateColumns}
        onRowClick={(index) => {
          if (fetchOrganizations.data?.organizations) {
            navigate(
              Routes.ParadiseOrganization.location(
                // @ts-expect-error: noUncheckedIndexedAccess
                fetchOrganizations.data.organizations[index].id
              )
            )
          }
        }}
      />
      {fetchOrganizations.isError && (
        <InfoBox type="warning" title={fetchOrganizations.error.message}>
          {fetchOrganizations.error.response?.data}
        </InfoBox>
      )}
      <ParadisePager
        limit={LIMIT}
        offset={offset}
        setOffset={setOffset}
        totalCount={fetchOrganizations.data?.paging.total_count}
      />
    </ParadiseLayout>
  )
}
